/* color */
:root{
    --color-text:white;
    --color-text-highlight:#61dafb;
    --color-text-darkblue: #141d2d;
    --color-text-lightgrey: #8297a0;
    --color-bg:#282c34;
    --color-bg-header:#2a2f37;
    --color-bg-light: #3b414e;
    --color-bg-hover: #3c434e;
    --color-sky-blue: #cff8f8;
    --color-white-smoke:#F5F5F5;
    --color-grey:lightgrey;
    --color-pearl:#FAF9F4;
    --color-dark-grey: #565555;
    --color-horizontal-line: #4a4949;
    --color-light-grey: #8a8383;
    --color-lightest-grey: #cac8c8;

}

:root{
    --font1:"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; ";
    --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}



