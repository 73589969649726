.dish-type-container{
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding-left: 1rem;
    padding-top: 0.2rem;
    /*border: #348a06 1px solid;*/
}

.dish-type{
    display: flex;
    width: 6rem;
    height: 2rem;
    margin: 0rem 0.6rem;
    background-color: steelblue;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    /*text-align: center;*/
    /*vertical-align: middle;*/

}

.dish-type:hover{
    display: flex;
    width: 6rem;
    height: 2rem;
    margin: 0rem 0.6rem;
    background-color: #5d97c8;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: #0b5a5d 2px solid;
    /*text-align: center;*/
    /*vertical-align: middle;*/

}

.dish-type-selected{
    display: flex;
    width: 6rem;
    height: 2rem;
    margin: 0rem 0.6rem;
    background-color: steelblue;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: #0b5a5d 2px solid;
    /*text-align: center;*/
    /*vertical-align: middle;*/

}

.dish-type-selected:hover{
    display: flex;
    width: 6rem;
    height: 2rem;
    margin: 0rem 0.6rem;
    background-color: #5d97c8;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    border: #0b5a5d 2px solid;
    /*text-align: center;*/
    /*vertical-align: middle;*/

}