.gamesSection{
    position: fixed;
    width: 9vw;
    height: 90vh;
    margin-left: 90vw;
    min-width: 15px;
    /*border: 2px red solid;*/
    /*transform: rotateX(90deg);*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.rotate{
    width: 100%;
    height: 80%;
    /*border: 2px solid white;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.game-text{
    /*border: 2px solid yellow;*/
    transform: rotate(90deg);
    display: block;
    margin-bottom: 60px;
    color: var(--color-text-highlight);
    font-size: 1.8rem;
}
.gameIcon{
    display: block;
    height: 35px;
    width: 35px;
    margin-bottom: 25px;
    /*border-radius: 50%;*/
}
.gameIcon:hover{
    height: 45px;
    width: 45px;
    margin-top: -5px;
    margin-bottom: 20px;
    /*box-shadow: 0px 0px 6px 2px var(--color-text-highlight);*/
    /*box-shadow: 0px 0px 6px 4px rgba(230, 196, 196, 0.3);*/
}
.line{
    display: block;
    height: 250px;
    width: 0px;
    border: 1px var(--color-white-smoke) solid;
}
