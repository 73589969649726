.loginContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;  /* need to know*/
    text-align: left;
}

.user__ID{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.userInput{

}

.user__Password{
    width: 30%;
    display: flex;
    flex-direction: column;
}
.password{

}

.Login__Action{
    margin-top: 1.1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
}

.Login__Action>button{
    background-color: #45a7b0;
    border-radius: 2px;
}

.Register__Action{
    margin-top: 1.1rem;
    margin-left: 1rem;
}
.Register__Action>button{
    font-size: 0.7rem;
    background-color: #337f86;
    border-radius: 2px;
}