.CartProceed{
    margin-top: 4px;
    padding-top: 2px;
    padding-bottom: 4px;
    display: flex;
    width: 100%;
    flex-direction: row;
    border-top: 2px solid #bebcbc;
}

.dummy{
    width: 20%;
    /*border: 2px solid red;*/
}
.CartTotalAmountText{
    font-family: "Apple Braille";
    font-weight: bold;
    width: auto;
    text-align: left;
    padding: 2px;
    margin-bottom: 4px;
    margin-right: 2px;
    /*margin-left: 1rem;*/
    color: #918f8f;
    /*border: blue 2px solid;*/
}

.CartTotalAmount{
    font-family: "Apple Braille";
    font-weight: bold;
    width: 25%;
    text-align: left;
    padding: 2px;
    margin-bottom: 4px;
    color: #8495ac;
}

.CartProceedButton{
    width: 11%;
    text-align: center;
    padding-right: 5px;
    border-radius: 8px;
    background-color: #055a53;
    /*border: 2px solid lightgray;*/
    margin-bottom: 4px;
    margin-left: 8rem;
    color: lightgray;
    font-weight: bold;
}