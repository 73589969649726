
/* About me*/

.aboutme{
    margin-top: 180px;
    font-family: Calibre;
    width: 100%;
    display: inline-block;
    text-align: left;
    /*align-items: center;*/
    margin-left: 10px;
    /*border: 1px red solid;*/
}
.aboutme>span{
    color: var(--color-text-highlight);
}

.horizontalLine{
    display: inline-block;
    margin-left: 30px;
    width: 20vw;
    height: 3px;
    margin-top: -4px;
    border-top: 1px solid var(--color-dark-grey);
}

/* About */

.About{
    display: flex;
    flex-direction: row;
    /*border: 1px solid red;*/
}

.AboutDesc{
    display: block;
    width: 60%;
    text-align: left;
    flex-direction: row;
    margin-left: 10px;
}

.h3-about{
    display: block;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0px;
    font-family: Calibre;
    /*border: 1px solid blue;*/


}


.AboutPic{
    width: 40%;
    display: block;
}

.mypic{
    width: 60%;
    height: 280px;
    border-radius: 4px;
}