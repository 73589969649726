.BodyContainer{
    display: none;
    height: 0px;
    /*visibility: hidden;*/
    flex-direction: row;
    margin-top: 5px;
    opacity: 0;  /* hide */
    animation: loadappBody 0s 5s forwards ;
    height: 0px;
}

@keyframes loadappBody {
    from{
        /*display: block;*/
        /*height: 0px;*/
        /*visibility: hidden;*/
        opacity: 0;
        /*height: 0;*/
    }
    70% {opacity:0;}
    80% {opacity:.1;}
    90% {opacity:.3;}
    100% {opacity:1;}
    to{
        /*display: block;*/
        opacity: 1;
        /*visibility: visible;*/
        /*height: auto;*/
    }
}