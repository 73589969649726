.path1 {
    stroke-dasharray: 2000;
    stroke-dashoffset: 100;
    animation: dash1 2s ease-in;

}

@keyframes dash1 {
    from {
        stroke-dashoffset: 2000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.path2 {
    stroke-dasharray: 800;
    stroke-dashoffset: 200;
    animation: dash2 3s ease-in;
}

@keyframes dash2 {
    from {
        stroke-dashoffset: 800;
    }
    to {
        stroke-dashoffset: 0;
    }
}


.hideIn5{
    z-index: 99;
    display: inline-block;
    /*border: 2px solid red;*/
    position: absolute;
    height: 70vh;
    width: 100vw;
    animation: hideIn5sec 4s forwards;
    animation-delay: 1s;
    background-color: var(--color-bg-header);
}

@keyframes hideIn5sec {
    0% {opacity: 1;}
    70% {opacity: .9;}
    80% {opacity: .7;}
    85% {opacity: .6;}
    90% {opacity: .5;}
    99% {opacity: 0;
        height: 70vh;
        width: 100vw;
        z-index: 0;
    }
    100% {opacity: 0;
        height: 0vh;
        width: 0vw;
        z-index: 99}
}