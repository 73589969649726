.main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.comingSoon{
    font-size: 2rem;
}

.desc{

}