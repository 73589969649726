/*var textcolor1=='#61dafb'*/

.header{
    /*border: 2px blue solid;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    height: 4vh;
    min-height: 4rem;
    min-width: 30rem;
    /*background-color: #2a2f37;*/
    z-index: 0;
    animation: cssAnimation 0s 4s forwards;
    opacity: 0;
}
@keyframes cssAnimation {
    50% {opacity:0.3}
    80% {opacity:0.6}
    90% {opacity:0.8}
    100% {opacity:1}
}

.menu{
    display: flex;
    justify-content: space-around;
    width: 45vw;
    min-width: 10rem;
    margin-top: 1.25rem;
    font-size: 0.9rem;
    margin-right: 2rem;
    animation: menuAnimation 0s 4.5s forwards;
    opacity: 0;
    /*border: 2px blue solid;*/
}
@keyframes menuAnimation {
    100% {opacity:1}
}
.menu >a{
    text-decoration: none;
    color: var(--color-text);
}

.menu >a> bdi{
    color: var(--color-text-highlight);
}

.menu >a:hover{
    color: var(--color-text-highlight);
}


.logo{
    /*border: 2px red solid;*/
    display: block;
    height: 100%;
    width: 5rem;
    margin-top: 10px;
    margin-left: 2.5rem;
    min-width: 5rem;
    opacity: 1;
    transition-duration: 2s;
}

.logo:hover{
    transform: rotateY(360deg);

}




/*.innerLogo{*/

/*}*/

/*.outerLogo{*/

/*}*/