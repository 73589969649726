.mainContainer{
    margin-top: 150px;
    /*margin-left: 100px;*/
    display: block;
    height: auto;
    /*border: 1px red solid;*/
    padding: 50px 0 50px 0;
}
.header{
    /*margin-top: 180px;*/
    font-family: Calibre;
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-bottom: 5px;
    position: relative;
    /*border: 1px red solid;*/
}
.header>span{
    color: var(--color-text-highlight);
}

.portfolioContainer{
    display: flex;
    flex-direction: row;
    margin: 50px 0 150px 0;
    /*border: 1px red solid;*/
}


.portfolioPic{
    width: 35vw;
    height: 300px;
    /*border: 1px red solid;*/
    border-radius: 8px;
}

.image{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0.5;
}

.image:hover{
    opacity: 1;
}

.portfolioDetails{
    width: 35vw;
    height: auto;
    /*border: 1px red solid;*/
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}
.portfolioDetails1{
    width: 35vw;
    height: auto;
    /*border: 1px red solid;*/
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.featured{
    font-family: var(--font-mono);
    font-size: .9rem;
    color: var(--color-text-highlight);
    margin: 20px 4px 0 0;
}
.appName{
    font-family: var(--font-sans);
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--color-white-smoke);
    margin: 10px 4px 20px 0;
}
.projectDescription{
    display: block;
    margin: 10px 4px 0 -10%;
    background-color: var(--color-bg-light);
    width: 105%;
    /*margin-left: -20%;*/
    font-family: var(--font-mono);
    font-size: 0.9rem;
    border-radius: 6px;
    padding: 30px 10px 30px 10px;
    color: var(--color-text-lightgrey);
    z-index: 2;
}

.projectDescription1{
    display: block;
    margin: 10px -10% 0 4px;
    background-color: var(--color-bg-light);
    width: 105%;
    /*margin-left: -20%;*/
    font-family: var(--font-mono);
    font-size: 0.9rem;
    border-radius: 6px;
    padding: 30px 10px 30px 10px;
    color: var(--color-text-lightgrey);
    z-index: 2;
}

.projectDescription span{
    color: var(--color-text-highlight);
}
.projectDescription1 span{
    color: var(--color-text-highlight);
}
.technology{
    margin: 20px 4px 0 0;
    /*padding: 2 2px 0 4px;*/
    width: 95%;
    font-family: var(--font-mono),var(--font-sans);
    font-size: 0.8rem;
    color: var(--color-text-lightgrey);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*border: 1px red solid;*/
}