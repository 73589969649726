.new-dish{
    position: relative;
    padding: 1rem;
    background-color: #18120f;
    /*opacity: 0.5;*/
    /*margin: 2rem auto;*/
    width: 40rem;
    max-width: 95%;
    color: white;
    font-size: 1rem;
    margin: 1rem;
    align-items: center;
    /*border: 2px red solid;*/
}

.dish-note{
    color: #3aafc4;
    font-size: 1.5rem;
    font-family: "Apple Braille";
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    display: block;
    /*border: 2px red solid;*/
}

.closeButton{
    position: absolute;
    width: 15px;
    height: 15px;
    display: block;
    top: -6px;
    right: -4px;

}