/*@font-face {*/
/*    font-family: 'Cassandra';*/
/*    src: url('../../../fonts/cassandra-font/CassandraPersonalUseRegular-3BjG.ttf');*/
/*}*/

/*@font-face {*/
/*    font-family: 'Cassandra';*/
/*    src: url('../../../fonts/cassandra-font/CassandraPersonalUseRegular-3BjG.ttf');*/
/*}*/

.h4-intro{
    text-align: left;
    margin-left: 10px;
    margin-top: 40px;
    font-family: Calibre;

}

.h1-name{
    text-align: left;
    margin-left: 10px;
    margin-top: -10px;
    margin-bottom: 0px;
    font-weight: bold;
    font-family: Calibre;
    /*font-family: var(--font1);*/
}

.h1-intro1{
    text-align: left;
    margin-left: 10px;
    margin-top: -10px;
    margin-bottom: 0px;
    font-weight: bold;
    font-family: Calibre;
    /*font-family: var(--font1);*/
}

.h2{
    text-align: left;
    margin-left: 10px;
    /*margin-top: -10px;*/
    margin-bottom: 0px;
    /*font-weight: bold;*/
    font-family: Calibre;
    /*font-family: var(--font1);*/
}

.h3-intro1{
    text-align: left;
    margin-left: 10px;
    width: 50%;
    /*margin-top: -10px;*/
    margin-bottom: 0px;
    /*font-weight: bold;*/
    font-family: Calibre;
    /*font-family: var(--font1);*/
}

.h1-offwhite{
    text-align: left;
    margin-left: 10px;
    margin-top: -10px;
    margin-bottom: 0px;
    font-weight: bold;
    font-family: Calibre;
    color: var(--color-white-smoke);
}

p>span{
    color: var(--color-text-highlight)
}




/* check my blogs*/
.checkMyBlog{
    border: 1px solid var(--color-text-highlight);
    width: 200px;
    margin-top: 60px;
    margin-left: 10px;
    border-radius: 4px;
    text-align: center;
    padding: 8px 5px 5px 5px;

    color: var(--color-text-highlight);
    font-family: Calibre;
    font-size: 1.2rem;
}

.checkMyBlog>a{
    text-decoration: none;
    color: var(--color-text-highlight);
    font-family: Calibre;
    font-size: 1.2rem;
}