.container{
    display: block;
    margin-left: 12rem;
}


.contact{
    margin-top: 10px;
    font-family: Calibre;
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-left: 10px;
    /*border: 1px red solid;*/
}
.contact1{
    margin-top: 10px;
    font-family: Calibre;
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-left: 10px;
    font-size: 0.9rem;
    /*border: 1px red solid;*/
}

.contact1 >span{
    color: var(--color-text-highlight);
}

.contact-form{
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    /*border: 1px solid var(--color-bg);*/
    padding: 10px;
    width: 500px;
    color: #F5F5F5;

}

.input-field{
    display: flex;
    background-color: var(--color-bg);
    flex-direction: column;
    border: 1px solid var(--color-bg);
    padding: 10px;
    width: 500px;
    border-radius: 4px;
    color: inherit;
}
.input-field>input{
    border: 0px solid transparent;
    border-bottom: 2px solid white;
    margin-top: 10px;
    width: 300px;
    height: 1.5rem;
    background-color: var(--color-bg);
    color: inherit;
    font-family: var(--font-sans);
}
.input-field>textarea{
    border: 1px solid transparent;
    border-bottom: 2px solid white;
    margin-top: 10px;
    width: 500px;
    height: 5rem;
    background-color: var(--color-bg);
    color: inherit;
}

.contact-form>button{

    margin-top: 10px;
    width: 100px;
    height: 1.5rem;
    margin-left: 200px;
    background-color: var(--color-text-highlight);
    border-radius: 4px;
    margin-bottom: 10px;
    /*font-family: var(--font1);*/
}

input:focus,   /* To remove outline when input form is selected */
select:focus,
textarea:focus,
button:focus {
    outline: none;
}