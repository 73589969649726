/* tooltip */
.tooltip{
    display: block;
    position: relative;
}

.tooltip > .tooltiptext{
    visibility: hidden;
    /*width: 120px;*/
    background-color: var(--color-white-smoke);
    color: black;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    margin-left: 20px;
    margin-top: -20px;
    padding: 2px;
    z-index: 1;
    font-size: .7rem;
    border: 1px white dotted;
    /*transition-delay: 0s;*/
}

.tooltip:hover .tooltiptext{
    visibility: visible;
    opacity: 0.6;
    transition-delay: 1s;
}

@font-face {
    font-family: 'Cassandra';
    src: url('./fonts/cassandra-font/CassandraPersonalUseRegular-3BjG.ttf');
}

@font-face {
    font-family: 'Calibre';
    src: url('./fonts/Calibre/Calibre-Regular.ttf');
}


/* Text Size*/

.h1size{
    font-size: 80px;
}

.h2size{
    font-size: 30px;
}

.h3size{
    font-size: 20px;
}

/* color*/
.whitecolor{
    color: white;
}

.highlighted{
    color: var(--color-text-highlight);
}

.colorpearl{
    color: var(--color-pearl);
}

.colorgrey{
    color: var(--color-grey);
}

.horizontalLine{
    display: inline-block;
    margin-left: 30px;
    width: 23vw;
    height: 5px;
    /*margin-top: -20px;*/
    border-top: 1px solid var(--color-horizontal-line);
    /*border-style: solid;*/
    /*border-width: 1px;*/
    /*border-image: linear-gradient(to right,var(--color-bg-header),var(--color-text-highlight),var(--color-bg-header)) 1 1%;*/
}