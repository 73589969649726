.mainContainer{
    position: fixed;
    width: 12vw;
    height: 90vh;
    min-width: 15px;
    /*border: 2px solid blue;*/
    display: flex;
    flex-direction: column-reverse;
}

.socialIconContainer{
    height: 70%;
    display: flex;
    /*border: 2px solid yellow;*/
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.socialIcon{
    /*border: var(--color-sky-blue) 1px solid;*/
    border-radius: 50%;
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
}
.socialIcon:hover{
    width: 35px;
    height: 35px;
    margin-bottom: 15px;
    /*margin-top: -5px;*/
    box-shadow: 0px 0px 6px 4px rgba(230, 196, 196, 0.3);
}

.socialPanelLine{
    margin-top: 30px;
    height: 20vh;
    width: 0px;
    border: 1px var(--color-white-smoke) solid;
}