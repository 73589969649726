html,body{
    overscroll-behavior: auto;
    background: var(--color-bg-header);
    /*border: 1px solid red;*/
}

.imatul{
    /*display: flex;*/
    /*margin-top: -10vh;*/
    /*margin-top: -10px;*/
    display: block;
    text-align: center;
    width: 100vw;
    /*height: 120vh;*/
    background: var(--color-bg);
    /*border: 2px solid blue;*/
}

.imatulheader{
    position: absolute;
    height: 100vh;
    /*width: 100vw;*/
    background-color: var(--color-bg-header);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    font-size: calc(10px + 2vmin);
    color: var(--color-text);
    /*border: 2px solid red;*/
}