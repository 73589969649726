.corporateExperience{
    margin-top: 100px;
    margin-left: 100px;
    display: block;
    height: auto;
    /*border: 1px red solid;*/
    padding: 50px 0 100px 0;
}

.header{
    /*margin-top: 180px;*/
    font-family: Calibre;
    width: 100%;
    display: inline-block;
    text-align: left;
    /*align-items: center;*/
    /*margin-left: 100px;*/
    /*border: 1px red solid;*/
    margin-bottom: 30px;
    position: relative;

}
.header>span{
    color: var(--color-text-highlight);
}


.menu {
    position: relative;
    height: auto;
    width: 600px;
    margin-left: -220px;
    /*margin: 0 0 0 100px;*/
    /*border: 1px solid RGBA(0, 0, 0, 0.4);*/
    /*font-family: calibri, monospace;*/
    display: inline-block;
}

.button{
    width: 120px;
    height: 32%;
    margin: 10px 0 10px 10px;
    background: var(--color-bg-header);
    border-radius: 4px;
    /*color: #e7e7e7;*/
    color: var(--color-text-highlight);
    font-family: var(--font-sans);
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    position: static;
    padding: 4px 0 0 4px;
    font-size: 1.1rem;
}

.content {
    position: absolute;
    background: var(--color-bg-header);
    width: 440px;
    height: 200px;
    margin-left: 155px;
    /*border: 1px solid #e7e7e7;*/
    display: none;
    text-align: start;
    justify-content: start;
    font-family: var(--font-sans);
    /*border: 3px solid red;*/
    font-size: 1.2rem;
    top: -1px;
    left: 1px;
}

.content>h5{
    align-items: start;
    justify-content: center;
    display: block;
    position: relative;
    margin: 0px;
    /*border: 1px solid #e7e7e7;*/
    color: var(--color-lightest-grey);
}
.content>h6{
    align-items: start;
    justify-content: start;
    display: block;
    position: relative;
    margin: 0px 0px 0px 1px;
    /*border: 1px solid #e7e7e7;*/
    color: var(--color-lightest-grey);
}

.content span{
    color: var(--color-text-highlight);
}
.content ul{
    list-style: square ;
    list-color: var(--color-text-highlight);

}
.content li{
    color: var(--color-light-grey);
    font-size: 1.1rem;
}
/*.content ul li::before{*/
/*    content: "\2022";*/
/*    color: var(--color-text-highlight);*/
/*}*/

.button:focus + .content {
    display: block;
    /*position: absolute;*/
    color: #F5F5F5;
    top: -1px;
    left: 1px;
}
.button:focus,
.button:hover {
    background-color: var(--color-bg-hover);
}


