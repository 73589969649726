.new-dish__controls{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;  /* need to know*/
    text-align: left;
    /*border: 1px solid #2857a4;*/
}
.new-dish__title__dishType{
    width: 20%;
    /*border: 1px solid red;*/
}
.new-dish__title__dishDescription{
    width: 40%;
    margin-left: 2rem;
    /*border: 1px solid red;*/
}
.new-dish__title{
    width: 40%;
    /*border: 1px solid red;*/
}
.new-dish__other{
    /*margin-left: 1%;*/
    width: 22%;
    /*border: 1px solid yellow;*/
}

.new-dish__control label{
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
}

.new-dish__control input{
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    /*width: 14rem;*/
    max-width: 100%;
}

.ne_Title{
    width: 90%;
    max-width: 90%;
}
.ne_Title__Description{
    width: 90%;
    max-width: 90%;
    height: 60px;
    padding: 12px 20px;
    /*margin-left: 2rem;*/
}

.ne_Other{
    /*margin-left: 20%;*/
    width: 90%;
    max-width: 90%;
}



.new-dish__action{
    text-align: right;
}

.new-dish__action button{
    border-radius: 8px;
    background-color: burlywood;
    height: 2rem;
}