.userAccount{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90px;
    height: auto;
    background: lightgray;
    border-radius: 4px;
    margin-top: 6.8rem;
    margin-left: 8rem;
    color: #666666;
    font-size: 0.8rem;
    transition: margin-left 2s;
}

.userAccountItems{
    font-family: "Apple Braille";
    font-weight: bold;
    border-bottom: 1px solid #c4c2c2;
    margin: 3px;
}

.userAccountItems:hover{
    background-color: #88cbcd;
}


.welcomeUser{
    font-family: "Apple Braille";
    font-weight: bold;
    color: #0b5a5d;
    /*font-size: 0.8rem;*/
    padding-left: 2px;
    background-color: #be9680;
    /*border-bottom: 2px solid #8bc3b5;*/
    border-radius:  4px 4px 0 0;
}

.userProfile{
    height: inherit;
    width: 20%;
    margin-top: 4px;
    margin-right: 6px;
    margin-left: 2px;

}