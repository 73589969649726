

html,body{
  overscroll-behavior: auto;
  background: var(--color-bg-header);
}

/*.App{*/
/*  !*display: flex;*!*/
/*  !*margin-top: -10vh;*!*/
/*  display: inline-block;*/
/*  text-align: center;*/
/*  width: 100vw;*/
/*  !*height: 120vh;*!*/
/*  background: var(--color-bg);*/
/*}*/

/*.App-header {*/
/*  position: absolute;*/
/*  height: 100vh;*/
/*  !*width: 100vw;*!*/
/*  background-color: var(--color-bg-header);*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: start;*/
/*  justify-content: start;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: var(--color-text);*/
/*  !*border: 2px solid red;*!*/
/*}*/











/*.initial-logo{*/

/*  display: block;*/
/*  background-color: #282c34;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background-color: #282c34;*/
/*  transition-property: all;*/
/*  transition-duration: 1s;*/
/*  transition-delay: 4s;*/
/*  z-index: 99;*/
/*}*/
/*.initial-logo:default{*/
/*  display: none;*/
/*  z-index: 0;*/
/*}*/




/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/



/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
